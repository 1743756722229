import React, { useState, useEffect } from "react";

import { Card, Row,  CardGroup } from "react-bootstrap";

import { Link } from "react-router-dom";

import api from '../services/api';


import pabloImg from "../bg/evento-do-pablo3.png";

const SeeNews = () => {

  const [resultado, setResultado] = useState([]);

  const sendVoto = async () => {
   const { data } = await api.get(`/noticias`);
   setResultado(data);
  }

  useEffect(() => {
    sendVoto();
  }, []);


  return (

    <div className="App">
      <header className="App-header">
        <CardGroup>
          <Row xs={1} md={2}>
          {resultado.map((noticia) => {
            return (
            <Card>
              <img class="flex" crossorigin="anonymous" src={noticia.urlimagem} ></img>
              <Card.Body>
                <Card.Title>{noticia.titulo}</Card.Title>
                <Card.Text>
                 {noticia.descricao}
                </Card.Text>
              </Card.Body>
              <Card.Link href={noticia.urlnoticia}>Link da Matéria</Card.Link>
              <Card.Footer>
                <small className="text-muted">{noticia.created_at}</small>
              </Card.Footer>
            </Card>
            )})}
          </Row>
        </CardGroup>
      </header>
    </div>
  );
};

export default SeeNews;