import { React, useEffect } from 'react';

import Modal from '../componentes/Cards';

const Home = () => {

  useEffect(() => {
  });

  return (
    <>
     <Modal />
    </>
  );
};

export default Home;